import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom/cjs/react-router-dom';
import Home from '../components/Home';
import Header from '../Layouts/Header';
import AboutUs from '../components/Aboutus';
import Footer from '../Layouts/Footer';
import ContactUsPage from '../components/Contactus';
import WebApplication from '../components/WebApplication';
import CloudSupport from '../components/CloudInfrasupport';
import GisMapping from '../components/NetworkingDevices';
import OilGas from '../components/VOIP';
import Building from '../components/ITSecurity';
import Utilities from '../components/NetworkingDevices';
import ManPowerServices from '../components/APN';
import Java from '../components/Servers';
import ITServices from '../components/ITServices';
import ITSupport from '../components/ITSupport';
import VOIP from '../components/VOIP';
import NetworkingDevices from '../components/NetworkingDevices';
import Servers from '../components/Servers';
import SoftwareSolutions from '../components/SoftwareSolutions';
import DesktopsLaptops from '../components/Testimonial';
import NotFound from '../components/404';
import ISO from '../components/ISO27001';
const Router = () => {
    return (
        <div>
            <Header />
            <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/Servers" component={Servers} />
                <Route path="/WebApplicaiton" component={WebApplication} />
                <Route path="/Desktops_Laptops" component={DesktopsLaptops} />
                <Route path="/Software_Solutions" component={SoftwareSolutions} />
                <Route path="/VOIP" component={VOIP} />
                <Route path="/Cloud_Support" component={CloudSupport} />
                <Route path="/IT_Services" component={ITServices} />
                <Route path="/Access_Points" component={ManPowerServices} />
                <Route path="/Networking_Devices" component={NetworkingDevices} />
                <Route path="/IT_Security" component={Building} />
                <Route path="/IT_Support" component={ITSupport} />
                <Route path="/Contact" component={ContactUsPage} />
                <Route path="/ISO" component={ISO} />
                
                {/* Redirect to NotFound if the route doesn't match */}
                <Route path="/*" component={NotFound} />
                <Redirect to="/*" />
            </Switch>
            <Footer />
        </div>
    );
}

export default Router;
