import React, { useState, useEffect } from 'react';
import img1 from '../assets/images/img123.png';
import ImageWithSkeleton from '../Layouts/Skeleton';
import video from '../assets/images/BgVideo.mp4';

const slides = [
  { image: img1 },
  // Add more images if needed
];

const SlideshowBanner = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState(true); // New loading state
  const [videoLoading, setVideoLoading] = useState(true); // Video loading state

  useEffect(() => {
    // Simulate server data fetching
    const fetchData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 2000)); // Simulate a 3-second loading time
      setLoading(false); // Data fetching complete
    };
    
    fetchData();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 999999);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const handleVideoLoad = () => {
    setVideoLoading(false);
  };

  return (
    <div>
      {!loading ? ( // Render slideshow or video based on loading state
        !isMobile ? (
          <div style={{ filter: 'brightness(0.9)' }} className="slideshow-banner position-relative">
            {slides.map((slide, index) => (
              <div
                key={index}
                className={`slide slide-${index} ${index === currentSlide ? 'active' : ''}`}
              >
                <div>
                  <ImageWithSkeleton
                    style={{
                      objectFit: 'cover',
                      borderRadius: '0px !important',
                      width: '100%',
                      height: '100vh',
                    }}
                    src={slide.image}
                  />
                </div>
                <div className="caption-container">
                  <h1 className="caption">{slide.caption}</h1>
                  {slide.subCaption && <h2 className="subCaption">{slide.subCaption}</h2>}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="video-container">
            {videoLoading && (
              <div className="video-skeleton-loader" />
            )}
            <video
              width="100%"
              height="100%"
              style={{ background: "#13132c", display: videoLoading ? "none" : "block" }}
              autoPlay
              loop
              muted
              onLoadedData={handleVideoLoad}
            >
              <source src={video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        )
      ) : (
        <div className="skeleton-loader" /> // Show a general skeleton loader while data is loading
      )}

      <style jsx>{`
        .slideshow-banner {
          position: relative;
          width: 100%;
          overflow: hidden;
        }
        .slide {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          opacity: 0;
          transition: opacity 1s ease-in-out;
        }
        .active {
          opacity: 1;
        }
        .caption-container {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          text-align: center;
          color: white;
          text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
        }
        .caption {
          font-size: 4vw;
          font-weight: bold;
          margin: 0;
        }
        .subCaption {
          font-size: 2vw;
          margin-top: 10px;
        }

        /* Responsive adjustments */
        @media (max-width: 1024px) {
          .caption {
            font-size: 6vw;
          }
          .subCaption {
            font-size: 3vw;
          }
        }

        @media (max-width: 768px) {
          .caption {
            font-size: 8vw;
          }
          .subCaption {
            font-size: 4vw;
          }
        }

        @media (max-width: 576px) {
          .caption {
            font-size: 10vw;
          }
          .subCaption {
            font-size: 5vw;
          }
        }

        .video-container {
          width: 100%;
          height: 100vh;
          background-color: black;
          position: relative;
        }
        
        .video-skeleton-loader {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100vh;
          background-color: #e0e0e0;
          animation: pulse 1.5s infinite;
        }

        .skeleton-loader {
          width: 100%;
          height: 100vh;
          background-color: #e0e0e0;
          animation: pulse 1.5s infinite;
        }

        @keyframes pulse {
          0% { opacity: 1; }
          50% { opacity: 0.5; }
          100% { opacity: 1; }
        }
      `}</style>
    </div>
  );
};

export default SlideshowBanner;
