// src/components/NotFound.js
import { height } from '@fortawesome/free-solid-svg-icons/fa0';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
const NotFound = () => {
    return (

        <div >
            <div style={{ height: "100vh" }} class="card bg-dark">
                <div class="card-body justify-content-center mt-8 d-flex text-center p-4 p-sm-5">
                    <div className=' align-items-center'>
                        <div class="fw-black lh-1 text-info fs-error">404</div>
                        <p class="lead mt-4 text-white font-sans-serif fw-semi-bold w-md-75 w-xl-100 mx-auto">The page you're looking for is not found.</p>
                        <Link to="/">
                            <Button size='md'>Go to Home</Button>
                        </Link>
                        <hr />
                        <p className='text-white'>Make sure the address is correct and that the page hasn't moved. If you think this is a mistake,</p>
                        {/* <a href="mailto:info@exmaple.com">contact us</a>.</p><a class="btn btn-primary btn-sm mt-3" href="../../index.html">
                        <svg class="svg-inline--fa fa-home fa-w-18 me-2" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="home" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" data-fa-i2svg=""><path fill="currentColor" d="M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z"></path></svg>
                        Take me home</a> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

const styles = {
    container: {
        textAlign: 'center',
        padding: '2rem',
        justifyContent: 'center'
    },
    header: {
        fontSize: '4rem',
        color: '#333',
    },
    message: {
        fontSize: '1.5rem',
        color: '#666',
    },
    link: {
        fontSize: '1rem',
        color: '#007bff',
        textDecoration: 'none',
    },
};

export default NotFound;
