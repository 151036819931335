import React, { Suspense, useEffect } from 'react';
import 'aos/dist/aos.css'; // Import AOS CSS file for styles
import AOS from 'aos';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const ISO = () => {

    useEffect(() => {
        // Initialize AOS when component mounts
        AOS.init({ duration: 1000 }); // Set the duration of animation
    }, []);

    return (
        <div className="software-servicesh">
            {/*  */}
            <section class="py-0 overflow-hidden" id="banner" data-bs-theme="light">
                <div class="bg-holder overlay bg-img-lnd"></div>
                <div class="">
                    <div data-aos="fade-left" style={{ minHeight: "100vh" }} class="row flex-center pt-8 pt-lg-8 bg-dark">
                        <div class="col-md-11 col-lg-8 col-xl-12 pb-7 pb-xl-5 text-center text-xl-start">
                            <h1 data-aos="fade" class="theme-text container fw-bold">
                                ISO 27001
                            </h1>
                            <p data-aos="fade" class="lead text-white container text-capitalize text-start ">
                                We specialize in providing ISO 27001 implementation and auditing services to ensure that your organization achieves certification in line with international standards. Our team of certified auditors offers comprehensive support, including effective training and implementation strategies tailored to your organization's unique requirements. With our expertise, we guide you through the certification process, ensuring thorough compliance and robust security practices to safeguard your valuable assets. Trust us to help your organization achieve ISO 27001 certification with confidence and efficiency.

                                <h4 data-aos="fade" class=" mt-3 theme-text fw-bold">
                                    What is ISO 27001?
                                </h4>
                                <p>
                                    ISO 27001 is an internationally recognized standard that outlines the requirements for establishing, implementing, maintaining, and continually improving an information security management system within an organization. Achieving ISO 27001 certification demonstrates a commitment to protecting sensitive information and mitigating risks associated with data breaches, cyber-attacks, and other security threats.
                                </p>
                                <h4 data-aos="fade" class="theme-text fw-bold">
                                    Benefits of ISO 27001 Certification:</h4>
                                <span className='d-block mt-2 mb-0'>

                                    <ul type="none">
                                        <li><FontAwesomeIcon icon={faCheckCircle} style={{ color: '#219bda' }} /> Enhanced Security: ISO 27001 certification helps strengthen your organization's security posture by identifying and mitigating information security risks.</li>
                                        <li><FontAwesomeIcon icon={faCheckCircle} style={{ color: '#219bda' }} /> Increased Trust and Credibility: Achieving ISO 27001 certification demonstrates your commitment to protecting sensitive information and instills confidence in your customers, partners, and stakeholders.</li>
                                        <li><FontAwesomeIcon icon={faCheckCircle} style={{ color: '#219bda' }} /> Legal and Regulatory Compliance: ISO 27001 certification can assist your organization in meeting legal and regulatory requirements related to information security and data protection.</li>
                                        <li><FontAwesomeIcon icon={faCheckCircle} style={{ color: '#219bda' }} /> Competitive Advantage: ISO 27001 certification can give you a competitive edge in the marketplace by demonstrating your commitment to best practices in information security management.</li>
                                    </ul>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
};

export default ISO;

